import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const appsConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: ['/apps/chat'],
        component: React.lazy(() => import('./ChatBot')),
      },
      {
        path: '/courses/course-details/:courseId/learning-event/:eventId/lesson/:lessonId',
        component: React.lazy(
          () =>
            import('../apps/TopicList/MechanicalPageComponents/EventDetail'),
        ),
      },
      {
        path: '/courses/course-details/:courseId/test/:testId',
        component: React.lazy(
          () => import('../apps/TopicList/CourseQuestions/index'),
        ),
      },
      {
        path: '/courses/course-details/:id',
        component: React.lazy(
          () =>
            import('../apps/TopicList/MechanicalPageComponents/CourseContent'),
        ),
      },
      {
        path: '/courses/:labelId',
        component: React.lazy(() => import('../apps/TopicList/index')),
      },
      {
        path: '/error-pages/coming-soon',
        component: React.lazy(() => import('../errorPages/ComingSoon')),
      },
    ],
  },
  {
    auth: authRole.admin,
    routes: [
      {
        path: ['/apps/courses-management/course-details/:id/events'],
        component: React.lazy(() => import('./CoursesManagement/EventSection')),
      },
      {
        path: ['/apps/courses-management/course-details/:id/invites'],
        component: React.lazy(
          () => import('./CoursesManagement/InvitationSection'),
        ),
      },
      {
        path: ['/apps/courses-management/course-details/:id/authors'],
        component: React.lazy(
          () => import('./CoursesManagement/AuthorSection'),
        ),
      },
      {
        path: '/admin-dashboard/contact-students-via-email',
        component: React.lazy(
          () => import('../apps/dashboard/contactStudents/sendEmail'),
        ),
      },
      {
        path: '/admin-dashboard/contact-students-via-digit',
        component: React.lazy(
          () => import('../apps/dashboard/contactStudents/sendViaDigit'),
        ),
      },
      {
        path: '/admin-dashboard/unanswered-questions',
        component: React.lazy(
          () => import('../apps/dashboard/unansweredQuestions'),
        ),
      },
      {
        path: '/admin-dashboard/statistics/course-info/:courseId/users',
        component: React.lazy(
          () => import('../apps/dashboard/statistics/courseStudents'),
        ),
      },
      {
        path: '/admin-dashboard/statistics',
        component: React.lazy(() => import('../apps/dashboard/statistics')),
      },
      {
        path: '/admin-dashboard/chatStatistics',
        component: React.lazy(() => import('../apps/dashboard/chatStatistics')),
      },
      {
        path: ['/admin-dashboard/users', '/admin-dashboard/users/:filter'],
        component: React.lazy(() => import('../apps/dashboard/users')),
      },
      {
        path: ['/admin-dashboard/surveys'],
        component: React.lazy(() => import('../apps/dashboard/surveys')),
      },
      {
        path: ['/admin-dashboard/automationFiles'],
        component: React.lazy(
          () => import('../apps/dashboard/automationFiles'),
        ),
      },
    ],
  },
  {
    auth: authRole.admin,
    routes: [
      {
        path: ['/apps/courses-management/course-details/:id/tests'],
        component: React.lazy(() => import('./CoursesManagement/TestSection')),
      },
      {
        path: ['/apps/courses-management/:id', '/apps/courses-management'],
        component: React.lazy(() => import('./CoursesManagement')),
      },
    ],
  },
  {
    auth: authRole.prof,
    routes: [
      {
        path: ['/apps/courses-management/course-details/:id/events'],
        component: React.lazy(() => import('./CoursesManagement/EventSection')),
      },
      {
        path: ['/apps/courses-management/course-details/:id/tests'],
        component: React.lazy(() => import('./CoursesManagement/TestSection')),
      },
      {
        path: ['/apps/courses-management/:id', '/apps/courses-management'],
        component: React.lazy(() => import('./CoursesManagement')),
      },
      {
        path: ['/apps/courses-management/course-details/:id/invites'],
        component: React.lazy(
          () => import('./CoursesManagement/InvitationSection'),
        ),
      },
      {
        path: ['/apps/courses-management/course-details/:id/authors'],
        component: React.lazy(
          () => import('./CoursesManagement/AuthorSection'),
        ),
      },
    ],
  },
];
