import React, {useEffect, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import useStyles from './index.style';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {Box, Slide} from '@material-ui/core';
import ChatContent from 'modules/apps/ChatBot/ChatContent';
import {useDispatch, useSelector} from 'react-redux';
import {onChangeDigitalEngWindow, onGetDigitEng} from 'redux/actions';
import {AppState} from 'redux/store';
import {ConnectionObj} from 'types/models/apps/Chat';

interface ChatBotProps {
  connectionList: ConnectionObj[];
}

const ChatBot: React.FC<ChatBotProps> = ({connectionList}) => {
  const {digitalEngWindowIsOpened} = useSelector<AppState, AppState['chatApp']>(
    ({chatApp}) => chatApp,
  );

  const [open, setCustomizerStatus] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    setCustomizerStatus(digitalEngWindowIsOpened);
  }, [dispatch, digitalEngWindowIsOpened]);

  useEffect(() => {
    if (!window.location.pathname.includes('chat'))
      dispatch(onGetDigitEng(connectionList));
  }, []);

  return (
    <React.Fragment>
      <Box className={clsx(classes.customizerOption, 'customizerOption')}>
        <Box className={classes.customizerButton}>
          <IconButton
            className={classes.textStyle}
            onClick={() => {
              dispatch(onChangeDigitalEngWindow(!open));
              if (!window.location.pathname.includes('chat'))
                dispatch(onGetDigitEng(connectionList));
            }}>
            {!open ? (
              <ModeCommentIcon
                className={`${classes.iconStyle} ${classes.chatIconAnimationClose}`}
              />
            ) : (
              <KeyboardArrowDownIcon className={classes.iconStyle} />
            )}
          </IconButton>
        </Box>
      </Box>
      <Slide timeout={1000} direction='up' in={open} mountOnEnter unmountOnExit>
        <Box className={open ? classes.show : classes.hidden}>
          <div className={classes.chatBotBox}>
            <ChatContent />
          </div>
        </Box>
      </Slide>
    </React.Fragment>
  );
};

export default ChatBot;
