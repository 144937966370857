import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {AuthUser} from '../../types/models/AuthUser';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import axios from 'axios';
import {
  GET_USER_PROFILE,
  SET_AUTH_TOKEN,
  SET_LOGIN_SUCCESS,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';
import {
  RegisterDTO,
  RegisterResponse,
  UpdateUserProfileDTO,
  UserInfoResponse,
  UserProfile,
  UserProfileResponse,
} from 'types/models/apps/Auth';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getUserInfo = (): Promise<UserInfoResponse> => {
  return jwtAxios.get('/users/info');
};

export const getUserProfile = (): Promise<UserProfileResponse> => {
  return jwtAxios.get('/users/profile');
};

export const updateUserProfile = (
  newProfile: UpdateUserProfileDTO,
): Promise<UserProfileResponse> => {
  return jwtAxios.put('/users/profile', newProfile);
};

export const register = (
  registerDTO: RegisterDTO,
  roll: string,
): Promise<RegisterResponse> => {
  if (roll === 'student')
    return jwtAxios.post('/api/registration/student/', registerDTO);
  else return jwtAxios.post('/api/registration/teacher/', registerDTO);
};

export const onJwtUserSignUp = (body: {
  email: string;
  password: string;
  name: string;
  role: string;
  firstName: string;
  lastName: string;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const registerDTO: RegisterDTO = {
        email: body.email,
        password1: body.password,
        password2: body.password,
        username: body.name,
        first_name: body.firstName,
        last_name: body.lastName,
      };
      const {status} = await register(registerDTO, body.role);
      if (status === 201) {
        dispatch(
          showMessage(
            'Your account has been created successifully, please wait for admin to verify your account',
          ),
        );
      } else {
        dispatch(fetchError('Error '));
      }
    } catch (err: any) {
      if (err.response.status === 400)
        dispatch(fetchError('Error, email or user name already exsits'));
      else dispatch(fetchError('Error '));
    }
  };
};

export const onJwtSignIn = (body: {username: string; password: string}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data} = await axios.post(
        'http://54.167.125.155:7008/rest-auth/login/',
        body,
      );
      localStorage.setItem('token', data.token);
      localStorage.setItem('username', data.username);
      localStorage.setItem('id', data.id);
      dispatch(setJWTToken(data.token));
      dispatch(setLogin());
      await loadJWTUser(dispatch, data.role);
    } catch (err: any) {
      if (err.response.status === 403)
        dispatch(
          fetchError(
            'Account locked for 24 hours: too many login attempts. Contact an admin to unlock your account.',
          ),
        );
      else if (err.response.status === 404)
        dispatch(fetchError('Wrong username or password'));
      else dispatch(fetchError('Error '));
    }
  };
};

export const loadJWTUser = async (
  dispatch: Dispatch<AppActions>,
  role: string,
) => {
  dispatch(fetchStart());
  try {
    dispatch(fetchSuccess());
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: getUserObject({
        displayName: localStorage.getItem('username'),
        email: localStorage.getItem('username'),
        role: ['user', role],
        token: localStorage.getItem('token'),
        uid: 2,
        _id: 2,
        photoURL: 'images/avatar/A24.jpg',
      }),
    });
  } catch (err) {
    dispatch(fetchError(JSON.stringify(err)));
  }
};

export const setJWTToken = (token: string | null): AppActions => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

export const setLogin = (): AppActions => ({
  type: SET_LOGIN_SUCCESS,
});

const getUserObject = (authUser: any): AuthUser => {
  return {
    authType: AuthType.JWT_AUTH,
    displayName: authUser.name,
    email: authUser.email,
    role: authUser.role,
    token: authUser._id,
    uid: authUser._id,
    photoURL: authUser.avatar,
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchSuccess());

    dispatch({type: SIGNOUT_AUTH_SUCCESS});
    dispatch(fetchSuccess());
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('ChatId');
    localStorage.removeItem('id');
    window.location.reload();
  };
};

export const onGetUserInfo = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status, data} = await getUserInfo();
      if (status === 200) {
        await loadJWTUser(dispatch, data.user_info.role);
      } else dispatch(fetchError('Error '));
    } catch (err: any) {
      dispatch(fetchError('Error '));
    }
  };
};

export const onGetUserProfile = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status, data} = await getUserProfile();
      if (status === 200) {
        const profile: UserProfile = {
          id: data.user.id,
          email: data.user.email,
          firstName: data.user.first_name,
          lastName: data.user.last_name,
          role: data.user.role,
          username: data.user.username,
          address: data.user.user_profile.address,
          country: data.user.user_profile.country,
          description: data.user.user_profile.description,
        };
        dispatch(fetchSuccess());
        dispatch({type: GET_USER_PROFILE, payload: profile});
      } else dispatch(fetchError('Error '));
    } catch (err: any) {
      dispatch(fetchError('Error '));
    }
  };
};

export const onUpdateUserProfile = (newProfile: UpdateUserProfileDTO) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await updateUserProfile(newProfile);
      if (status === 200) {
        dispatch(fetchSuccess());
      } else dispatch(fetchError('Error '));
    } catch (err: any) {
      dispatch(fetchError('Error '));
    }
  };
};
