export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'Welcome',
    title: 'Welcome',
    messageId: 'sidebar.pages.extraPages.welcome',
    type: 'item',
    icon: 'work_outline',
    url: '/welcome',
  },
  {
    id: 'chat',
    title: 'Chat',
    messageId: 'sidebar.apps.chat',
    type: 'item',
    icon: 'sidebar.apps.chat',
    url: '/apps/chat',
  },
  {
    id: 'web-viewer',
    title: 'Web Viewer',
    messageId: 'sidebar.pages.extraPages.webviewer',
    type: 'item',
    icon: 'view_comfy_alt_icon',
    url: '/web-viewer',
  },
  {
    id: 'multi-level',
    title: 'Multi Level',
    messageId: 'sidebar.topicList',
    type: 'collapse',
    icon: 'menu',
    children: [
      {
        id: 'course-1-1',
        title: 'Course 1',
        messageId: 'sidebar.topicList.topic1',
        type: 'item',
        url: '/courses/1',
      },
      {
        id: 'course-1-2',
        title: 'Course 2',
        messageId: 'sidebar.topicList.topic2',
        type: 'item',
        url: '/courses/2',
      },
    ],
  },
  {
    id: 'courses-management',
    title: 'Courses Management',
    messageId: 'sidebar.coursesManagement',
    type: 'item',
    icon: 'auto_stories_icon',
    url: '/apps/courses-management',
    auth: ['admin', 'professor'],
  },
  {
    id: 'admin-dashboard',
    title: 'Admin Dashboard',
    messageId: 'sidebar.adminDashboard',
    type: 'collapse',
    icon: 'dashboard_icon',
    auth: ['admin'],
    children: [
      {
        id: 'unanswered-questions',
        title: 'Unanswered Questions',
        messageId: 'sidebar.adminDashboard.unansweredQuetions',
        type: 'item',
        url: '/admin-dashboard/unanswered-questions',
      },
      {
        id: 'contact-std',
        title: 'Contact Students',
        messageId: 'sidebar.adminDashboard.contactStudents',
        type: 'collapse',
        children: [
          {
            id: 'via-email',
            title: 'Via Email',
            messageId: 'sidebar.adminDashboard.contactStudentsViaEmail',
            type: 'item',
            url: '/admin-dashboard/contact-students-via-email',
          },
          {
            id: 'via-digit',
            title: 'Via digit',
            messageId: 'sidebar.adminDashboard.contactStudentsViaDigit',
            type: 'item',
            url: '/admin-dashboard/contact-students-via-digit',
          },
        ],
      },
      {
        id: 'statistics',
        title: 'statistics',
        messageId: 'sidebar.adminDashboard.statistics',
        type: 'item',
        url: '/admin-dashboard/statistics',
      },
      {
        id: 'chatStatistics',
        title: 'chatStatistics',
        messageId: 'sidebar.adminDashboard.chatStatistics',
        type: 'item',
        url: '/admin-dashboard/chatStatistics',
      },
      {
        id: 'users',
        title: 'users',
        messageId: 'sidebar.adminDashboard.users',
        type: 'item',
        url: '/admin-dashboard/users',
      },
      {
        id: 'surveys',
        title: 'surveys',
        messageId: 'sidebar.adminDashboard.surveys',
        type: 'item',
        url: '/admin-dashboard/surveys',
      },
      {
        id: 'automationFiles',
        title: 'automationFiles',
        messageId: 'sidebar.adminDashboard.automationFiles',
        type: 'item',
        url: '/admin-dashboard/automationFiles',
      },
    ],
  },
];
export default routesConfig;
