import clsx from 'clsx';
import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import {Button} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import {AuthUser} from 'types/models/AuthUser';
import {MessageDataObj, MessageType} from 'types/models/apps/Chat';

import {getFileSize} from '@crema/utility/Utils';
import Linkify from 'react-linkify';
import useStyles from './MessageItem.style';
import {Link} from 'react-router-dom';

const getMessage = (item: MessageDataObj, classes: any) => {
  if (item.message_type === MessageType.TEXT) {
    return (
      <Box component='p' mb={1} ml={3} textAlign='left'>
        <Linkify
          componentDecorator={(
            decoratedHref: string,
            decoratedText: string,
            key: number,
          ) => (
            <a
              href={decoratedHref}
              style={{wordBreak: 'break-all'}}
              key={key}
              target='_blank'
              rel='noreferrer'>
              {decoratedText}
            </a>
          )}>
          {item.message}
        </Linkify>
      </Box>
    );
  } else if (item.message_type === MessageType.CLICKABLE) {
    return (
      <Box>
        <Button
          className={classes.ClickableMessageButton}
          variant='contained'
          color='primary'
          disableElevation>
          {item.message}
        </Button>
      </Box>
    );
  } else if (item.message_type === MessageType.DOC && item.media) {
    return (
      <Box display='flex' flexWrap='nowrap'>
        <Box
          component='span'
          className='pointer'
          display='flex'
          flexWrap='nowrap'>
          <Box component='span' display='inline-block' mr={2}>
            <Box>{item.media.file_name}</Box>
            <Box>{getFileSize(item.media.file_size)}</Box>
          </Box>
        </Box>
      </Box>
    );
  } else if (item.message_type === MessageType.LINK) {
    return (
      <Box component='p' mb={1} ml={3}>
        <Link to={item?.url || ''} color='inherit'>
          {item.message}
        </Link>
      </Box>
    );
  } else if (item.media) {
    return (
      <Box className={classes.mediaWrapper}>
        {item.media.mime_type.startsWith('video') ? (
          <Box display='flex'>
            <video src={item.media.url} />
            <PlayCircleOutlineIcon className={classes.videoIcon} />
          </Box>
        ) : (
          <img alt='' src={item.media.url} />
        )}
      </Box>
    );
  }
};

interface SenderMessageItemProps {
  item: MessageDataObj;
  authUser: AuthUser;
  onClickClickableMessage: (item: MessageDataObj) => void;
}

const SenderMessageItem: React.FC<SenderMessageItemProps> = ({
  authUser,
  item,
  onClickClickableMessage,
}) => {
  const [, onOpenMoreIcon] = useState(null);

  const onViewMoreOpen = (event: any) => {
    onOpenMoreIcon(event.currentTarget);
  };

  const clickableMessage = (message: string) => {
    onClickClickableMessage({
      message,
      message_type: MessageType.TEXT,
      sender: {id: +(localStorage.getItem('id') + '')},
      time: '',
    });
  };
  const getUserAvatar = () => {
    const name = authUser.displayName;
    if (name) {
      return name.charAt(0).toUpperCase();
    }
    if (authUser.email) {
      return authUser.email.charAt(0).toUpperCase();
    }
  };

  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.messageItemRoot, 'right')}
      display='flex'
      justifyContent={'flex-end'}>
      <Box className={classes.messageChatRoot}>
        <Box
          className={clsx(classes.messageTime, 'message-time')}
          component='span'>
          {item.time}
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          {item.message_type === MessageType.TEXT && item.media && (
            <Box className='pointer' component='span' mt='auto'>
              <a href={item.media!.url} download={item.media!.file_name}>
                <img alt='' src={'/assets/images/icon-download.svg'} />
              </a>
            </Box>
          )}
          {item.message_type === MessageType.CLICKABLE ? (
            <Box
              onClick={() => {
                clickableMessage(item.message || '');
              }}
              className={classes.ClickableMessageChat}
              ml='auto'>
              <Box className='message-info'>{getMessage(item, classes)}</Box>
            </Box>
          ) : (
            <Box className={classes.messageChat} ml='auto'>
              <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>

              <Box className='message-info'>
                {getMessage(item, classes)}

                {item.edited && (
                  <Box className={classes.editRoot}>
                    <EditIcon />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {item.message_type === MessageType.CLICKABLE ? null : (
        <Box className={classes.arrowIcon}>
          <Box
            ml={2}
            className={classes.textPointer}
            component='span'
            color='text.disabled'>
            <MoreVertIcon onClick={onViewMoreOpen} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SenderMessageItem;
