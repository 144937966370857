import {GetUserMessagesDTO, MessageDataObj} from 'types/models/apps/Chat';
import {dateFormat} from '../dateFormater';

export function getQuestion(msg: string) {
  return msg.substring(11);
}

export function questionHandle(msg: GetUserMessagesDTO): MessageDataObj {
  const result = getQuestion(msg.content);
  const id = +result.split('-')[0];
  const question = result.split('-')[1];
  return {
    id: msg.id,
    sender: {id: msg.contact.user.id, name: msg.contact.user.username},
    questionObj: {
      id,
      question,
    },
    message_type: 8,
    time: dateFormat(msg.timestamp),
  };
}

export function getChoice(msg: string) {
  return msg.substring(9);
}

export function choiceHandle(msg: GetUserMessagesDTO): MessageDataObj {
  const result = getChoice(msg.content);
  const id = +result.split('-')[0];
  const choice = result.split('-')[1];
  return {
    id: msg.id,
    sender: {id: msg.contact.user.id, name: msg.contact.user.username},
    choiceObj: {id, choice},
    message_type: 9,
    time: dateFormat(msg.timestamp),
  };
}
