import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {fetchStart, fetchSuccess, setJWTToken} from '../../redux/actions';
import {AppState} from '../../redux/store';
import {AuthUser} from '../../types/models/AuthUser';
import {UPDATE_AUTH_USER} from 'types/actions/Auth.actions';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const useAuthToken = (): [boolean, AuthUser | null] => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

  useEffect(() => {
    const validateAuth = async () => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch(fetchSuccess());
        return;
      }
      dispatch(setJWTToken(token));
      try {
        const {data} = await jwtAxios.get('/users/info/');
        localStorage.setItem('id', data.user_info.id);
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_AUTH_USER,
          payload: {
            uid: data.user_info.id,
            displayName: localStorage.getItem('username'),
            email: localStorage.getItem('username'),
            role: ['user', data.user_info.role],
            token: localStorage.getItem('token'),
            photoURL: 'images/avatar/A24.jpg',
          },
        });
        return;
      } catch (err) {
        dispatch(fetchSuccess());
        return;
      }
    };

    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch]);

  return [loading, user];
};

export const useAuthUser = (): AuthUser | null => {
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  if (user) {
    return user;
  }
  return null;
};
