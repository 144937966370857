import {Response} from '../Common';

export enum MessageType {
  MEDIA = 1,
  DOC = 2,
  TEXT = 3,
  CLICKABLE = 4,
  LINK = 5,
  EQUATION = 6,
  DISAPPEAR = 7,
  QUESTION = 8,
  CHOICE = 9,
}

export interface ConnectionObj {
  id: number;
  channelId: number;
  name: string;
  image: string;
  status: string;
  username: string;
  lastMessage?: {
    id: number;
    message: string;
    type: string;
    time: string;
  };
}

export interface MediaObj {
  url: string;
  mime_type: string;
  file_name: string;
  file_size: number;
}

export interface QuestionMessageObj {
  id: number;
  question: string;
}

export interface ChoiceMessageObj {
  id: number;
  choice: string;
}

export interface MessageDataObj {
  id?: number;
  sender: {id: number; name?: string};
  message?: string;
  mixedTypeMessage?: MixedTypeMessage[];
  message_type: MessageType;
  time: string;
  edited?: boolean;
  media?: MediaObj;
  url?: string;
  questionObj?: QuestionMessageObj;
  choiceObj?: ChoiceMessageObj;
}

export interface MixedTypeMessage {
  part: string;
  type: MessageType;
}

export interface MessageObj {
  channelId: number;
  messageData: MessageDataObj[];
}

export interface GetConnectionListResponse
  extends Response<{connections_list: GetConnectionListDTO[]}> {}

export interface GetConnectionListDTO {
  id: number;
  username: string;
  chat_id: number;
}

export interface GetUserMessagesDTO {
  id: number;
  contact: {
    user: {id: number; username: string; email?: string};
  };
  content: string;
  timestamp: string;
}

export interface GetUserMessagesResponse
  extends Response<{messages: GetUserMessagesDTO[]}> {}

export interface WebsocketMessage {
  id: number;
  content: string;
  author: number;
  timestamp: string;
  username: string;
  chat_id: number;
}
