import {getFileSize} from '@crema/utility/Utils';
import {Box, Button} from '@material-ui/core';
import React from 'react';
import {Link} from 'react-router-dom';
import {
  ConnectionObj,
  MessageDataObj,
  MessageObj,
  MessageType,
} from 'types/models/apps/Chat';
import {useDispatch, useSelector} from 'react-redux';
import {onSendChoice, onSendMessage} from 'redux/actions';
import {AppState} from 'redux/store';
import MathView from 'react-math-view';
import Linkify from 'react-linkify';

const myObj = {
  [MessageType.MEDIA]: (item: MessageDataObj, classes?: any) => (
    <Box className={classes.mediaWrapper}>
      {item.media!.mime_type.startsWith('video') ? (
        <Box display='flex'>
          <video width={'100%'} controls={true} src={item.media!.url} />
        </Box>
      ) : (
        <img alt='' src={item.media!.url} />
      )}
    </Box>
  ),
  [MessageType.DOC]: (item: MessageDataObj, classes?: any) => (
    <Box display='flex' flexWrap='nowrap'>
      <img alt='' src={'/assets/images/icon-docs-dark.svg'} />
      <Box component='span' display='inline-block' ml={2}>
        <Box>{item.media!.file_name}</Box>
        <Box>{getFileSize(item.media!.file_size)}</Box>
      </Box>
    </Box>
  ),
  [MessageType.TEXT]: (item: MessageDataObj, classes?: any) => (
    <Box component='p' mb={1} ml={3}>
      <Linkify
        componentDecorator={(
          decoratedHref: string,
          decoratedText: string,
          key: number,
        ) => (
          <a
            href={decoratedHref}
            style={{wordBreak: 'break-all'}}
            key={key}
            target='_blank'
            rel='noreferrer'>
            {decoratedText}
          </a>
        )}>
        {item.message}
      </Linkify>
    </Box>
  ),
  [MessageType.DISAPPEAR]: (item: MessageDataObj, classes?: any) => (
    <Box component='p' mb={1} ml={3}>
      <Linkify
        componentDecorator={(
          decoratedHref: string,
          decoratedText: string,
          key: number,
        ) => (
          <a
            href={decoratedHref}
            style={{wordBreak: 'break-all'}}
            key={key}
            target='_blank'
            rel='noreferrer'>
            {decoratedText}
          </a>
        )}>
        {item.message}
      </Linkify>
    </Box>
  ),
  [MessageType.CLICKABLE]: (
    item: MessageDataObj,
    dispatch: any,
    selectedUser: ConnectionObj,
    connectionList: ConnectionObj[],
    userMessages: MessageObj,
    classes?: any,
  ) => (
    <>
      <Box className={classes.clickableBox}>
        <Button
          onClick={() => {
            dispatch(
              onSendMessage(
                selectedUser.channelId,
                {
                  message: item.message,
                  message_type: MessageType.TEXT,
                  sender: {id: +(localStorage.getItem('id') + '')},
                  time: '',
                },
                connectionList,
              ),
            );
          }}
          variant='contained'
          color='primary'
          disableElevation>
          {item.message?.toLocaleLowerCase().startsWith('{x_t*}')
            ? item.message.substring(6)
            : item.message?.substring(11)}
        </Button>
      </Box>
    </>
  ),
  [MessageType.LINK]: (item: MessageDataObj, classes?: any) => (
    <Box className={classes.root} component='p' mb={1} ml={3}>
      <Link
        to={item?.url || ''}
        color='inherit'
        style={{textDecoration: 'none', color: 'black'}}>
        {item.message}
      </Link>
    </Box>
  ),
  [MessageType.EQUATION]: (item: MessageDataObj, classes?: any) => (
    <Box component='p' mb={1} ml={3}>
      {item.mixedTypeMessage?.map((msg) =>
        msg.type === 6 ? (
          <MathView
            style={{
              color: 'black',
            }}
            value={msg.part}></MathView>
        ) : (
          msg.part
        ),
      )}
    </Box>
  ),
  [MessageType.QUESTION]: (item: MessageDataObj, classes?: any) => (
    <Box component='p' mb={1} ml={3}>
      {item.questionObj?.question}
    </Box>
  ),
  [MessageType.CHOICE]: (
    item: MessageDataObj,
    dispatch: any,
    selectedUser: ConnectionObj,
    connectionList: ConnectionObj[],
    userMessages: MessageObj,
    classes?: any,
  ) => (
    <>
      <Box className={classes.clickableBox}></Box>
      <Box px={8} py={4} component='span' m={1} className={classes.spreadBox}>
        <Button
          onClick={() => {
            if (item.choiceObj)
              dispatch(
                onSendChoice(
                  selectedUser.channelId,
                  item.choiceObj?.choice,
                  item.choiceObj?.id,
                  connectionList,
                ),
              );
          }}>
          {item.message}
        </Button>
      </Box>
    </>
  ),
};
export const GetMessage = (item: MessageDataObj, classes: any) => {
  const dispatch = useDispatch();
  const {selectedUser}: {selectedUser: ConnectionObj} = useSelector<
    AppState,
    AppState['chatApp']
  >(({chatApp}) => chatApp);
  const {
    connectionList,
    userMessages,
  }: {connectionList: ConnectionObj[]; userMessages: MessageObj} = useSelector<
    AppState,
    AppState['chatApp']
  >(({chatApp}) => chatApp);
  const jsx = myObj[item.message_type](
    item,
    dispatch,
    selectedUser,
    connectionList,
    userMessages,
    classes,
  );
  return jsx;
};
