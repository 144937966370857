import {
  GetUserMessagesDTO,
  MessageDataObj,
  MessageType,
  MixedTypeMessage,
} from 'types/models/apps/Chat';
import {dateFormat} from '../dateFormater';

export interface Element {
  part: string;
  type: MessageType;
  index: number;
}

export function getEquationPart(msg: string) {
  let result: Element[] = [];
  const res = msg.substring(12).replace(/\n/g, '');
  res.split(/text {[^}]*}/).forEach((str) => {
    result.push({part: str, type: 6, index: msg.indexOf(str)});
  });
  return result;
}

export function getTextPart(msg: string) {
  let result: Element[] = [];
  const regexp = new RegExp('text {[^}]*}', 'g');
  const res = msg.substring(12).replace(/\n/g, '');
  const matches = res.matchAll(regexp);
  for (const match of matches) {
    result.push({
      part: match[0].substring(6, match[0].length - 1),
      type: 3,
      index: match.index ? match.index : 0,
    });
  }
  return result;
}

export function mergeSorted(first: Element[], second: Element[]) {
  let result = [...first, ...second];
  return result.sort((a, b) => a.index - b.index);
}

export function equationHandle(msg: GetUserMessagesDTO): MessageDataObj {
  const equationPart = getEquationPart(msg.content);
  const textPart = getTextPart(msg.content);
  let result: MixedTypeMessage[] = mergeSorted(equationPart, textPart);
  return {
    id: msg.id,
    sender: {id: msg.contact.user.id, name: msg.contact.user.username},
    mixedTypeMessage: result,
    message_type: 6,
    time: dateFormat(msg.timestamp),
  };
}
