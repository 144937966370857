import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import {useDispatch, useSelector} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {onReceiveMessage, onSendChoice, onSendMessage} from 'redux/actions';
import AddNewMessage from './AddNewMessage';
import MessagesList from './MessagesList';
import {useAuthUser} from '@crema/utility/AppHooks';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import IntlMessages from '@crema/utility/IntlMessages';
import grey from '@material-ui/core/colors/grey';
import {useAppsContentStyles} from '@crema/core/AppsContainer/AppsContent';
import AppsFooter from '@crema/core/AppsContainer/AppsFooter';
import {
  ConnectionObj,
  GetUserMessagesDTO,
  MessageDataObj,
  MessageObj,
  MessageType,
  WebsocketMessage,
} from 'types/models/apps/Chat';
import {AppState} from 'redux/store';
import WebSocketInstance from '@crema/services/websocket';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {ignoredMessage, messageHandle} from '@crema/services/messageHandler';

const useStyles = makeStyles(() => ({
  scrollChatNomain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100% !important',
  },
  noMessage: {
    fontSize: 18,
    color: grey[500],
  },
}));

interface MessagesScreenProps {
  selectedUser: ConnectionObj;
}

const MessagesScreen: React.FC<MessagesScreenProps> = ({selectedUser}) => {
  const [message, setMessage] = useState<string | undefined>('');
  const dispatch = useDispatch();
  const user = useAuthUser();
  let _scrollBarRef: any = null;
  let history = useHistory();
  const {userMessages}: {userMessages: MessageObj} = useSelector<
    AppState,
    AppState['chatApp']
  >(({chatApp}) => chatApp);

  const {connectionList}: {connectionList: ConnectionObj[]} = useSelector<
    AppState,
    AppState['chatApp']
  >(({chatApp}) => chatApp);
  useEffect(() => {
    const messagesFunc = (messages: WebsocketMessage[]) => {
      messages.forEach((message) => {
        const msg: GetUserMessagesDTO = {
          id: message.id,
          contact: {user: {id: message.author, username: message.username}},
          content: message.content,
          timestamp: message.timestamp,
        };
        if (!ignoredMessage(msg.content)) {
          const response: MessageDataObj = messageHandle(msg);
          dispatch(
            onReceiveMessage(
              selectedUser.channelId,
              response,
              connectionList,
              selectedUser,
            ),
          );
        }
      });
    };
    const newMessageFunc = (message: WebsocketMessage) => {
      const msg: GetUserMessagesDTO = {
        id: message.id,
        contact: {user: {id: message.author, username: message.username}},
        content: message.content,
        timestamp: message.timestamp,
      };
      if (!ignoredMessage(msg.content)) {
        const response: MessageDataObj = messageHandle(msg);
        dispatch(
          onReceiveMessage(
            message.chat_id,
            response,
            connectionList,
            selectedUser,
          ),
        );
      }
    };

    const courseInfoFunc = (message: WebsocketMessage) => {
      const msg: GetUserMessagesDTO = {
        id: message.id,
        contact: {user: {id: message.author, username: message.username}},
        content: message.content,
        timestamp: message.timestamp,
      };
      if (!ignoredMessage(msg.content)) {
        const response: MessageDataObj = messageHandle(msg);

        if (+(localStorage.getItem('id') + '') !== message.author)
          setTimeout(() => {
            dispatch(
              onReceiveMessage(
                selectedUser.channelId,
                response,
                connectionList,
                selectedUser,
              ),
            );
          }, 1500);
        else
          dispatch(
            onReceiveMessage(
              selectedUser.channelId,
              response,
              connectionList,
              selectedUser,
            ),
          );
      }
    };

    WebSocketInstance.addCallbacks(
      messagesFunc,
      newMessageFunc,
      courseInfoFunc,
    );
  }, [connectionList, dispatch, selectedUser]);

  useEffect(() => {
    if (
      userMessages &&
      userMessages.messageData &&
      userMessages.messageData.length > 0
    ) {
      if (_scrollBarRef) {
        // THAT IS RESPONSIPLE FOR SCROLL DOWN AUTOMATICALLY WHEN YOU SEND A NEW MESSAGE
        _scrollBarRef._container.scrollTop = 99999;
      }
    }
  }, [userMessages, _scrollBarRef]);

  const onSend = (message: string) => {
    if (message === 'visualize model' || message === 'view model') {
      history.push('/web-viewer');
    } else {
      const data: MessageDataObj = {
        message,
        message_type: MessageType.TEXT,
        sender: {id: +(localStorage.getItem('id') + '')},
        time: moment().format('llll'),
      };
      const lastMessage =
        userMessages.messageData[userMessages.messageData.length - 1];
      if (
        lastMessage &&
        lastMessage.message_type === MessageType.QUESTION &&
        lastMessage.questionObj
      )
        dispatch(
          onSendChoice(
            selectedUser.channelId,
            message,
            lastMessage.questionObj?.id,
            connectionList,
          ),
        );
      else
        dispatch(onSendMessage(selectedUser.channelId, data, connectionList));
      setMessage('');
    }
  };

  const onClickClickableMessage = (data: MessageDataObj) => {
    if (data.message) onSend(data.message);
  };

  const appsContentStyles = useAppsContentStyles({
    isDetailView: false,
    fullView: false,
  });

  const classes = useStyles();
  return (
    <Box display='flex' height={1} flexDirection='column'>
      {userMessages && user ? (
        <PerfectScrollbar
          ref={(ref) => {
            _scrollBarRef = ref;
          }}
          className={appsContentStyles.appsContentContainer}>
          <MessagesList
            userMessages={userMessages}
            authUser={user}
            selectedUser={selectedUser}
            onClickClickableMessage={onClickClickableMessage}
          />
        </PerfectScrollbar>
      ) : (
        <Box
          className={clsx(classes.scrollChatNomain, 'scroll-chat-nomain')}
          component='span'>
          <Box component='span' className={classes.noMessage}>
            <IntlMessages id='chatApp.sayHi' /> {selectedUser.name}
          </Box>
        </Box>
      )}

      <AppsFooter mt='auto'>
        <AddNewMessage currentMessage={message} onSendMessage={onSend} />
      </AppsFooter>
    </Box>
  );
};

export default MessagesScreen;
