import {GetUserMessagesDTO, MessageDataObj} from 'types/models/apps/Chat';
import {dateFormat} from '../dateFormater';

export function getVideoUrl(msg: string) {
  return msg.substring(8);
}

export function videoHandle(msg: GetUserMessagesDTO): MessageDataObj {
  const videoUrl = getVideoUrl(msg.content);
  return {
    id: msg.id,
    sender: {id: msg.contact.user.id, name: msg.contact.user.username},
    media: {
      url: videoUrl,
      mime_type: 'video',
      file_name: videoUrl,
      file_size: 10,
    },
    message_type: 1,
    time: dateFormat(msg.timestamp),
  };
}
