import {
  CompletionRateIncrease,
  CourseObj,
  EventObj,
  InviteObj,
  AuthorObj,
  LessonObj,
  QuestionObj,
  TestObj,
  UpdatedCourseObj,
  UpdatedEventObj,
  UpdatedInviteObj,
  UpdatedLessonObj,
  UpdatedTestObj,
} from 'types/models/apps/Course';

export const CREATE_NEW_COURSE = 'CREATE_NEW_COURSE';
export const CREATE_NEW_EVENT = 'CREATE_NEW_EVENT';
export const CREATE_NEW_LESSON = 'CREATE_NEW_LESSON';
export const GET_COURSE_LIST = 'GET_COURSE_LIST';
export const GET_COURSE_LESSONS_LIST = 'GET_COURSE_LESSONS_LIST';
export const GET_COURSE_DETAIL = 'GET_COURSE_DETAIL';
export const GET_COURSE_COMPLETION_RATE = 'GET_COURSE_COMPLETION_RATE';
export const GET_COURSE_COMPLETION_RATE_INCREASE =
  'GET_COURSE_COMPLETION_RATE_INCREASE';
export const DELETE_COURSE = 'DELETE_COURSE';
export const GET_TEST_QUESTIONS = 'GET_TEST_QUESTIONS';
export const GET_COURSE_TESTS = 'GET_COURSE_TESTS';
export const CREATE_NEW_TEST = 'CREATE_NEW_TEST';
export const CREATE_NEW_QUESTION = 'CREATE_NEW_QUESTION';
export const GET_EVENT_LESSONS = 'GET_EVENT_LESSONS';
export const GET_COURSE_EVENTS = 'GET_COURSE_EVENTS';
export const GET_COURSE_INVITES = 'GET_COURSE_INVITES';
export const GET_COURSE_AUTHORS = 'GET_COURSE_AUTHORS';
export const GET_AUTHORS_LIST = 'GET_AUTHORS_LIST';

export const UPDATE_COURSE_DETAIL = 'UPDATE_COURSE_DETAIL';
export const GET_TEST_RESULTS = 'GET_TEST_RESULTS';
export const UPDATE_EVENT_DETAIL = 'UPDATE_EVENT_DETAIL';
export const UPDATE_INVITE_DETAIL = 'UPDATE_INVITE_DETAIL';
export const UPDATE_LESSON_DETAIL = 'UPDATE_LESSON_DETAIL';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_INVITE = 'DELETE_INVITE';
export const DELETE_AUTHOR = 'DELETE_AUTHOR';
export const DELETE_TEST = 'DELETE_TEST';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_LESSON = 'DELETE_LESSON';
export const UPDATE_TEST_DETAIL = 'UPDATE_TEST_DETAIL';
export const UPDATE_QUESTION_DETAIL = 'UPDATE_QUESTION_DETAIL';
export const MARK_EVENT_AS_COMPLETED = 'MARK_EVENT_AS_COMPLETED';

export interface CreateNewCourseActions {
  type: typeof CREATE_NEW_COURSE;
  payload: CourseObj;
}

export interface CreateNewEventActions {
  type: typeof CREATE_NEW_EVENT;
  payload: EventObj;
}

export interface CreateNewLessonActions {
  type: typeof CREATE_NEW_LESSON;
  payload: {lesson: LessonObj; eventId: number};
}

export interface GetCourseListActions {
  type: typeof GET_COURSE_LIST;
  payload: {
    list: CourseObj[];
    total: number;
  };
}

export interface GetCourseLessonsListActions {
  type: typeof GET_COURSE_LESSONS_LIST;
  payload: {
    courseId: number;
    lessons: LessonObj[];
  };
}

export interface GetCourseDetailActions {
  type: typeof GET_COURSE_DETAIL;
  payload: CourseObj;
}

export interface GetCourseCompletionRateActions {
  type: typeof GET_COURSE_COMPLETION_RATE;
  payload: number;
}

export interface GetCourseCompletionRateIncreaseActions {
  type: typeof GET_COURSE_COMPLETION_RATE_INCREASE;
  payload: CompletionRateIncrease;
}

export interface DeleteCourseActions {
  type: typeof DELETE_COURSE;
  payload: number;
}

export interface GetTestQuestionsActions {
  type: typeof GET_TEST_QUESTIONS;
  payload: {testId: number; questions: QuestionObj[]};
}

export interface GetCourseTestsActions {
  type: typeof GET_COURSE_TESTS;
  payload: {courseId: number; tests: TestObj[]};
}

export interface CreateNewTestActions {
  type: typeof CREATE_NEW_TEST;
  payload: TestObj;
}

export interface CreateNewQuestionActions {
  type: typeof CREATE_NEW_QUESTION;
  payload: {testId: number; question: QuestionObj};
}

export interface GetEventLessonsActions {
  type: typeof GET_EVENT_LESSONS;
  payload: {eventId: number; lessons: LessonObj[]};
}

export interface GetCourseEventsActions {
  type: typeof GET_COURSE_EVENTS;
  payload: {courseId: number; events: EventObj[]};
}
export interface GetCourseInvitesActions {
  type: typeof GET_COURSE_INVITES;
  payload: {courseId: number; invites: InviteObj[]};
}

export interface GetCourseAuthorsActions {
  type: typeof GET_COURSE_AUTHORS;
  payload: {courseId: number; authors: AuthorObj[]};
}
export interface GetAuthorsListActions {
  type: typeof GET_AUTHORS_LIST;
  payload: {courseId: number; authors: AuthorObj[]};
}

export interface UpdateCourseDetailActions {
  type: typeof UPDATE_COURSE_DETAIL;
  payload: UpdatedCourseObj;
}

export interface GetTestResultsActions {
  type: typeof GET_TEST_RESULTS;
  payload: {answers: number[]; testId: number};
}

export interface UpdateEventDetailActions {
  type: typeof UPDATE_EVENT_DETAIL;
  payload: {eventId: number; event: UpdatedEventObj};
}

export interface UpdateInviteDetailActions {
  type: typeof UPDATE_INVITE_DETAIL;
  payload: {inviteId: number; invite: UpdatedInviteObj};
}
export interface UpdateTestDetailActions {
  type: typeof UPDATE_TEST_DETAIL;
  payload: {testId: number; test: UpdatedTestObj};
}

export interface UpdateLessonDetailActions {
  type: typeof UPDATE_LESSON_DETAIL;
  payload: {lessonId: number; lesson: UpdatedLessonObj};
}

export interface UpdateQuestionDetailActions {
  type: typeof UPDATE_QUESTION_DETAIL;
  payload: {questionId: number; question: QuestionObj};
}

export interface DeleteEventActions {
  type: typeof DELETE_EVENT;
  payload: number;
}

export interface DeleteInviteActions {
  type: typeof DELETE_INVITE;
  payload: number;
}

export interface DeleteAuthorActions {
  type: typeof DELETE_AUTHOR;
  payload: number;
}
export interface DeleteTestActions {
  type: typeof DELETE_TEST;
  payload: number;
}

export interface DeleteQuestionActions {
  type: typeof DELETE_QUESTION;
  payload: number;
}

export interface DeleteLessonActions {
  type: typeof DELETE_LESSON;
  payload: number;
}

export interface MarkEventAsCompletedActions {
  type: typeof MARK_EVENT_AS_COMPLETED;
  payload: number;
}

export type CourseActions =
  | CreateNewCourseActions
  | CreateNewEventActions
  | CreateNewLessonActions
  | GetCourseListActions
  | GetCourseLessonsListActions
  | GetCourseDetailActions
  | GetCourseCompletionRateActions
  | GetCourseCompletionRateIncreaseActions
  | DeleteCourseActions
  | GetCourseTestsActions
  | CreateNewTestActions
  | CreateNewQuestionActions
  | GetTestQuestionsActions
  | GetEventLessonsActions
  | GetCourseEventsActions
  | GetCourseInvitesActions
  | GetCourseAuthorsActions
  | GetAuthorsListActions
  | UpdateCourseDetailActions
  | UpdateEventDetailActions
  | UpdateInviteDetailActions
  | UpdateTestDetailActions
  | GetTestResultsActions
  | DeleteEventActions
  | DeleteInviteActions
  | DeleteLessonActions
  | UpdateQuestionDetailActions
  | DeleteQuestionActions
  | DeleteTestActions
  | DeleteAuthorActions
  | MarkEventAsCompletedActions
  | UpdateLessonDetailActions;
