import {
  CHANGE_USER_BLOCK_STATUS,
  DELETE_AUTOMATION_FILES,
  DELETE_MESSAGES_WITH_DIGITAL_ENGINEER,
  DELETE_USER,
  GET_AUTOMATION_FILES,
  GET_CONNECTIONS_LIST,
  GET_COURSE_LIST,
  GET_STATISTICS,
  GET_UNANSWERED_QUESTIONS,
  GET_USERS,
  GET_USER_MESSAGES,
  GET_USER_SURVERYS,
  SELECT_USER,
  StatisticsActions,
  UPLOAD_AUTOMATION_FILES,
  VERIFY_USER,
} from 'types/actions/Statistics.actions';
import {CourseObj} from 'types/models/apps/Course';
import {
  ConnectionObj,
  FileObj,
  MessageObj,
  StatisticsNumbers,
  SurveyObj,
  UnansweredQuestion,
  UserObj,
} from 'types/models/apps/Statistics';

const initialState: {
  unansweredQuestions: UnansweredQuestion[];
  statisticsNumbers: StatisticsNumbers | null;
  connectionList: ConnectionObj[];
  userMessages: MessageObj | null;
  selectedUser: ConnectionObj | null;
  users: UserObj[];
  coursesList: CourseObj[];
  userSurvey: SurveyObj[];
  automationFiles: FileObj[];
} = {
  unansweredQuestions: [],
  statisticsNumbers: null,
  connectionList: [],
  userMessages: null,
  selectedUser: null,
  users: [],
  coursesList: [],
  userSurvey: [],
  automationFiles: [],
};

const Statistics = (state = initialState, action: StatisticsActions) => {
  switch (action.type) {
    case GET_UNANSWERED_QUESTIONS:
      return {
        ...state,
        unansweredQuestions: action.payload,
      };

    case GET_STATISTICS:
      return {
        ...state,
        statisticsNumbers: action.payload,
      };

    case GET_CONNECTIONS_LIST:
      return {
        ...state,
        connectionList: action.payload,
      };

    case GET_USER_MESSAGES:
      return {
        ...state,
        userMessages: action.payload,
      };

    case SELECT_USER: {
      return {
        ...state,
        selectedUser: action.payload,
      };
    }

    case GET_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case DELETE_USER: {
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    }

    case CHANGE_USER_BLOCK_STATUS: {
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload ? {...user, blocked: !user.blocked} : user,
        ),
      };
    }

    case VERIFY_USER: {
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload ? {...user, verfied: true} : user,
        ),
      };
    }

    case GET_COURSE_LIST:
      return {
        ...state,
        coursesList: action.payload,
      };

    case GET_USER_SURVERYS:
      return {
        ...state,
        userSurvey: action.payload,
      };

    case DELETE_MESSAGES_WITH_DIGITAL_ENGINEER: {
      return {
        ...state,
        userMessages:
          state.userMessages?.channelId === action.payload
            ? {channelId: state.userMessages?.channelId, messageData: []}
            : state.userMessages,
      };
    }

    case GET_AUTOMATION_FILES: {
      return {
        ...state,
        automationFiles: action.payload,
      };
    }

    case DELETE_AUTOMATION_FILES: {
      return {
        ...state,
        automationFiles: state.automationFiles.filter(
          (file) => file.id !== action.payload,
        ),
      };
    }

    case UPLOAD_AUTOMATION_FILES: {
      return {
        ...state,
        automationFiles: state.automationFiles.concat(action.payload),
      };
    }

    default:
      return state;
  }
};

export default Statistics;
