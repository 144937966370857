import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

import {
  CreateCourseDTO,
  CreateCourseReponse,
  CreateLessonDTO,
  CreateLessonReponse,
  DeleteCourseResponse,
  EnrolCourseReponse,
  GetCompletionIncreaseRateResponse,
  GetCompletionRateResponse,
  GetCourseLessonsReponse,
  GetTestQuestionsResponse,
  GetCourseReponse,
  GetCoursesResponse,
  SendAnswersDTO,
  SendAnswersResponse,
  GetCourseTestsResponse,
  GetCourseStatusReponse,
  CreateTestResponse,
  CreateTestDTO,
  CreateQuestionDTO,
  CreateQuestionResponse,
  UnenrolCourseReponse,
  GetEventResponse,
  GetEventLessonsResponse,
  CreateEventDTO,
  CreateInvitationtDTO,
  CreateEventResponse,
  CreateInviteResponse,
  GetCourseEventsResponse,
  GetCourseInvitesResponse,
  GetCourseAuthorsResponse,
  UpdateCourseResponse,
  GetTestResultsResponse,
  UpdateEventResponse,
  UpdateInviteResponse,
  UpdateLessonResponse,
  DeleteEventResponse,
  DeleteAuthorResponse,
  AddAuthorResponse,
  DeleteLessonResponse,
  UpdateTestResponse,
  UpdateQuestionResponse,
  DeleteTestResponse,
  DeleteQuestionResponse,
  MarkEventAsCompletedResponse,
  UploadFileToLessonResponse,
  UploadVideoToLessonResponse,
} from '../../../../types/models/apps/Course';

export function getProfCourses(): Promise<GetCoursesResponse> {
  return jwtAxios.get('/myCourses/');
}

export function getAllCourses(): Promise<GetCoursesResponse> {
  return jwtAxios.get('/courses/');
}

export function createCourse(
  createCourseDTO: CreateCourseDTO,
): Promise<CreateCourseReponse> {
  return jwtAxios.post('/courses/', createCourseDTO);
}

export function createLesson(
  createLessonDTO: CreateLessonDTO,
  eventId: number,
): Promise<CreateLessonReponse> {
  return jwtAxios.post(`/events/${eventId}/lessons`, createLessonDTO);
}

export function getCourseLessons(
  courseId: number,
): Promise<GetCourseLessonsReponse> {
  return jwtAxios.get(`/courses/${courseId}/lessons/`);
}

export function getCourseById(courseId: number): Promise<GetCourseReponse> {
  return jwtAxios.get(`/courses/${courseId}`);
}

export function enrolCourse(c_id: number): Promise<EnrolCourseReponse> {
  return jwtAxios.post(`/courses/${c_id}/enroll`);
}

export function getCourseStatus(c_id: number): Promise<GetCourseStatusReponse> {
  return jwtAxios.get(`/courses/${c_id}/enroll_status`);
}

export function sendAnswers(
  answers: SendAnswersDTO,
  t_id: number,
): Promise<SendAnswersResponse> {
  return jwtAxios.post(`/tests/${t_id}/Results/`, answers);
}

export function getCompletionRate(
  c_id: string,
): Promise<GetCompletionRateResponse> {
  return jwtAxios.get(`/courses/${c_id}/completionRate`);
}

export function getCompletionIncreaseRate(
  c_id: string,
): Promise<GetCompletionIncreaseRateResponse> {
  return jwtAxios.get(`/courses/${c_id}/competencyIncreaseChart`);
}

export function deleteCourse(courseId: number): Promise<DeleteCourseResponse> {
  return jwtAxios.delete(`/courses/${courseId}`);
}

export function getTestQuestions(
  testId: number,
): Promise<GetTestQuestionsResponse> {
  return jwtAxios.get(`/tests/${testId}/questions`);
}

export function getCourseTests(
  courseId: number,
): Promise<GetCourseTestsResponse> {
  return jwtAxios.get(`/courses/${courseId}/tests`);
}

export function createTest(
  courseId: number,
  createTestDTO: CreateTestDTO,
): Promise<CreateTestResponse> {
  return jwtAxios.post(`/courses/${courseId}/tests`, createTestDTO);
}

export function createQuestion(
  testId: number,
  createQuestionDTO: CreateQuestionDTO,
): Promise<CreateQuestionResponse> {
  return jwtAxios.post(`/tests/${testId}/questions`, createQuestionDTO);
}

export function unenrolCourse(courseId: number): Promise<UnenrolCourseReponse> {
  return jwtAxios.post(`/courses/${courseId}/unenroll`);
}

export function getEventById(eventId: number): Promise<GetEventResponse> {
  return jwtAxios.get(`/courses/events/${eventId}`);
}

export function getCourseEvents(
  courseId: number,
): Promise<GetCourseEventsResponse> {
  return jwtAxios.get(`/courses/${courseId}/events`);
}

export function getCourseInvites(
  courseId: number,
): Promise<GetCourseInvitesResponse> {
  return jwtAxios.get(`/courses/${courseId}/invites`);
}

export function getCourseAuthors(
  courseId: number,
): Promise<GetCourseAuthorsResponse> {
  return jwtAxios.get(`/courses/${courseId}/authors`);
}

export function getAuthorsList(): Promise<GetCourseAuthorsResponse> {
  return jwtAxios.get(`/authors`);
}

export function getEventLessons(
  eventId: number,
): Promise<GetEventLessonsResponse> {
  return jwtAxios.get(`/events/${eventId}/lessons`);
}

export function createEvent(
  courseId: number,
  createEventDTO: CreateEventDTO,
): Promise<CreateEventResponse> {
  return jwtAxios.post(`/courses/${courseId}/events`, createEventDTO);
}

export function createInvitation(
  courseId: number,
  createInvitationDTO: CreateInvitationtDTO,
): Promise<CreateInviteResponse> {
  return jwtAxios.post(`/courses/${courseId}/invites`, createInvitationDTO);
}

export function deleteEvent(eventId: number): Promise<DeleteEventResponse> {
  return jwtAxios.delete(`/courses/events/${eventId}`);
}

export function deleteInvitation(
  inviteId: number,
): Promise<DeleteEventResponse> {
  return jwtAxios.delete(`/courses/invites/${inviteId}`);
}
export function deleteAuthor(
  authorId: number,
  courseId: number,
): Promise<DeleteAuthorResponse> {
  return jwtAxios.delete(`/courses/${courseId}/authors/${authorId}`);
}

export function addAuthor(
  authorId: number,
  courseId: number,
): Promise<AddAuthorResponse> {
  return jwtAxios.post(`/courses/${courseId}/authors/${authorId}`);
}

export function deleteTest(testId: number): Promise<DeleteTestResponse> {
  return jwtAxios.delete(`/courses/tests/${testId}`);
}

export function deleteQuestion(
  questionId: number,
): Promise<DeleteQuestionResponse> {
  return jwtAxios.delete(`/tests/questions/${questionId}`);
}

export function deleteLesson(lessonId: number): Promise<DeleteLessonResponse> {
  return jwtAxios.delete(`/events/lessons/${lessonId}`);
}

export function updateEvent(
  eventId: number,
  newEvent: CreateEventDTO,
): Promise<UpdateEventResponse> {
  return jwtAxios.put(`/courses/events/${eventId}`, newEvent);
}

export function updateInvite(
  inviteId: number,
  newInvite: CreateInvitationtDTO,
): Promise<UpdateInviteResponse> {
  return jwtAxios.put(`/courses/invites/${inviteId}`, newInvite);
}

export function updateCourse(
  courseId: number,
  newCourse: CreateCourseDTO,
): Promise<UpdateCourseResponse> {
  return jwtAxios.put(`/courses/${courseId}`, newCourse);
}

export function updateTest(
  testId: number,
  newTest: CreateTestDTO,
): Promise<UpdateTestResponse> {
  return jwtAxios.put(`/courses/tests/${testId}`, newTest);
}

export function getTestResults(
  testId: number,
): Promise<GetTestResultsResponse> {
  return jwtAxios.get(`/tests/${testId}/Results/`);
}

export function sendMessageViaDigitByCourse(courseId: number, message: string) {
  return jwtAxios.post(`/sendMessageFromDigitalEngineer/${courseId}`, {
    message,
  });
}

export function updateLesson(
  lessonId: number,
  newLesson: CreateLessonDTO,
): Promise<UpdateLessonResponse> {
  return jwtAxios.put(`/events/lessons/${lessonId}`, newLesson);
}

export function updateQuestion(
  questionId: number,
  newQue: CreateQuestionDTO,
): Promise<UpdateQuestionResponse> {
  return jwtAxios.put(`/tests/questions/${questionId}`, newQue);
}

export function markEventAsCompleted(
  eventId: number,
): Promise<MarkEventAsCompletedResponse> {
  return jwtAxios.post(`/events/${eventId}/complete`);
}

export function uploadFileToLesson(
  lessonId: number,
  formData: FormData,
): Promise<UploadFileToLessonResponse> {
  return jwtAxios.post(`/lessons/${lessonId}/files/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function uploadVideoToLesson(
  lessonId: number,
  formData: FormData,
): Promise<UploadVideoToLessonResponse> {
  return jwtAxios.post(`/lessons/${lessonId}/video/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
