import {appIntl} from '../../@crema/utility/Utils';
import {Dispatch} from 'redux';
import {AppActions} from '../../types';
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {
  createCourse,
  createLesson,
  enrolCourse,
  getCompletionRate,
  getCompletionIncreaseRate,
  getCourseById,
  getCourseLessons,
  getProfCourses,
  sendAnswers,
  deleteCourse,
  getTestQuestions,
  getCourseTests,
  createTest,
  unenrolCourse,
  getAllCourses,
  getEventLessons,
  createEvent,
  createInvitation,
  getCourseEvents,
  getCourseInvites,
  getCourseAuthors,
  getAuthorsList,
  deleteEvent,
  deleteInvitation,
  deleteAuthor,
  addAuthor,
  updateCourse,
  getTestResults,
  updateEvent,
  updateInvite,
  sendMessageViaDigitByCourse,
  updateLesson,
  deleteLesson,
  updateTest,
  createQuestion,
  updateQuestion,
  deleteTest,
  deleteQuestion,
  markEventAsCompleted,
  uploadFileToLesson,
  uploadVideoToLesson,
} from '@crema/services/apis/course';
import {
  CourseObj,
  CreateCourseDTO,
  CreateEventDTO,
  CreateInvitationtDTO,
  CreateLessonDTO,
  CreateQuestionDTO,
  CreateTestDTO,
  EventObj,
  LessonObj,
  QuestionObj,
  QuestionType,
  SendAnswersDTO,
  TestObj,
  TestType,
  UpdatedCourseObj,
  UpdatedLessonObj,
  InviteObj,
  UpdatedTestObj,
} from 'types/models/apps/Course';
import {
  CREATE_NEW_COURSE,
  CREATE_NEW_EVENT,
  CREATE_NEW_LESSON,
  CREATE_NEW_QUESTION,
  CREATE_NEW_TEST,
  DELETE_COURSE,
  DELETE_EVENT,
  DELETE_INVITE,
  DELETE_AUTHOR,
  DELETE_LESSON,
  DELETE_QUESTION,
  DELETE_TEST,
  GET_COURSE_COMPLETION_RATE,
  GET_COURSE_COMPLETION_RATE_INCREASE,
  GET_COURSE_DETAIL,
  GET_COURSE_EVENTS,
  GET_COURSE_INVITES,
  GET_COURSE_AUTHORS,
  GET_AUTHORS_LIST,
  GET_COURSE_LESSONS_LIST,
  GET_COURSE_LIST,
  GET_COURSE_TESTS,
  GET_EVENT_LESSONS,
  GET_TEST_QUESTIONS,
  GET_TEST_RESULTS,
  UPDATE_COURSE_DETAIL,
  UPDATE_EVENT_DETAIL,
  UPDATE_INVITE_DETAIL,
  UPDATE_LESSON_DETAIL,
  UPDATE_QUESTION_DETAIL,
  UPDATE_TEST_DETAIL,
  MARK_EVENT_AS_COMPLETED,
} from 'types/actions/Course.action';
import {blue, green} from '@material-ui/core/colors';
import {dateFormat} from '@crema/services/dateFormater';

const MeLabel = {
  id: 1,
  name: 'Mechanical Engineering',
  color: green[500],
};

const ThLabel = {
  id: 2,
  name: 'Thermal Engineering',
  color: blue[500],
};

export const onGetProfCoursesList = () => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getProfCourses();
      if (status === 200) {
        dispatch(fetchSuccess());
        let courses: CourseObj[];

        courses = data.courses.map((course) => ({
          id: course.id,
          title: course.c_title,
          summary: course.summary,
          label: course.category.toLowerCase().includes('me')
            ? [MeLabel]
            : course.category.toLowerCase().includes('th')
            ? [ThLabel]
            : [],
          events: [],
          invites: [],
          authors: [],
          authorList: [],
          tests: [],
        }));
        dispatch({
          type: GET_COURSE_LIST,
          payload: {list: courses, total: courses.length},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetAllCoursesList = () => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getAllCourses();
      if (status === 200) {
        dispatch(fetchSuccess());
        let courses: CourseObj[];

        courses = data.courses.map((course) => ({
          id: course.id,
          title: course.c_title,
          summary: course.summary,
          label: course.category.toLowerCase().includes('me')
            ? [MeLabel]
            : course.category.toLowerCase().includes('th')
            ? [ThLabel]
            : [],
          events: [],
          invites: [],
          authors: [],
          authorList: [],
          tests: [],
          disabled: course.disabled,
          startDate: dateFormat(course.start_time),
          endDate: dateFormat(course.end_time),
          chat: {id: course.chat.id, title: course.chat.title},
        }));
        dispatch({
          type: GET_COURSE_LIST,
          payload: {list: courses, total: courses.length},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onCreateCourse = (createCourseDTO: CreateCourseDTO) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const courseLabel = {
        id: createCourseDTO.category === 'ME' ? 1 : 2,
        name:
          createCourseDTO.category === 'ME'
            ? 'Mechanical Engineering'
            : 'Thermal Engineering',
        color: createCourseDTO.category === 'ME' ? green[500] : blue[500],
      };
      const {data, status} = await createCourse(createCourseDTO);

      if (status === 201) {
        dispatch(fetchSuccess());
        dispatch({
          type: CREATE_NEW_COURSE,
          payload: {
            id: data.course.id,
            title: createCourseDTO.c_title,
            summary: createCourseDTO.summary,
            label: [courseLabel],
            events: [],
            invites: [],
            tests: [],
            authors: [],
            authorList: [],
          },
        });
        dispatch(showMessage(messages['course.created'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (error) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetSelectedCourse = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCourseById(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        const course: CourseObj = {
          id: data.course.id,
          title: data.course.c_title,
          summary: data.course.summary,
          events: data.course.events.map((event) => {
            return {...event, lessons: []};
          }),
          label: [data.course.category === 'ME' ? MeLabel : ThLabel],
          invites: [],
          authors: [],
          authorList: [],
          tests: data.course.tests.map((test) => {
            return {
              id: test.id,
              name: test.name,
              summary: test.summary,
              type: test.is_postTest
                ? TestType.POST
                : test.is_preTest
                ? TestType.PRE
                : undefined,
              questions: test.questions
                ? test.questions.map((que) => {
                    return {...que, answer_value: 0};
                  })
                : [],
            };
          }),
          disabled: data.course.disabled,
          startDate: dateFormat(data.course.start_time),
          endDate: dateFormat(data.course.end_time),
          chat: {id: data.course.chat.id, title: data.course.chat.title},
        };
        dispatch({
          type: GET_COURSE_DETAIL,
          payload: course,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onCreateLesson = (
  createLessonDTO: CreateLessonDTO,
  eventId: number,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await createLesson(createLessonDTO, eventId);

      if (status === 201) {
        const lesson: LessonObj = {
          id: data.lesson.id,
          l_title: data.lesson.l_title,
          content: data.lesson.content,
          files: [],
        };
        dispatch(fetchSuccess());
        dispatch({type: CREATE_NEW_LESSON, payload: {lesson, eventId}});
        dispatch(showMessage(messages['lesson.created'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onCreateQuestion = (testId: number, que: CreateQuestionDTO) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await createQuestion(testId, que);
      if (status === 201) {
        const question: QuestionObj = {
          id: data.questions.id,
          content: data.questions.content,
          answer_type: data.questions.answer_type,
          answer_value: 0,
        };
        dispatch(fetchSuccess());
        dispatch({type: CREATE_NEW_QUESTION, payload: {testId, question}});
        dispatch(showMessage(messages['question.created'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseLessonsList = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCourseLessons(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_COURSE_LESSONS_LIST,
          payload: {courseId, lessons: data.lessons},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onEnrolCourse = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await enrolCourse(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        if (data.status === 'you enrolled the course successfully')
          dispatch(showMessage(messages['enrol.success'] as string));
        else if (data.status === 'you have already enrolled the course')
          dispatch(fetchError(messages['enrol.fail'] as string));
        else
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUnenrolCourse = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await unenrolCourse(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        if (data.status === "you've unenrolled the course successfully")
          dispatch(showMessage(messages['unenrol.success'] as string));
        else if (data.status === 'you are not enrolled in the course')
          dispatch(fetchError(messages['unenrol.fail'] as string));
        else
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseCompletionRate = (courseId: string) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCompletionRate(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_COURSE_COMPLETION_RATE,
          payload: data.comp_rate,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onSendStudentAnswers = (answers: SendAnswersDTO, t_id: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await sendAnswers(answers, t_id);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch(showMessage(messages['course.formSended'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseCompletionIncreaseRate = (courseId: string) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCompletionIncreaseRate(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_COURSE_COMPLETION_RATE_INCREASE,
          payload: data.cir,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onDeleteCourse = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteCourse(courseId);
      if (status === 204) {
        dispatch(fetchSuccess());
        dispatch({
          type: DELETE_COURSE,
          payload: ~~courseId,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onDeletMultiCourses = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteCourse(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: DELETE_COURSE,
          payload: ~~courseId,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetTestQuestions = (testId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getTestQuestions(testId);
      if (status === 200) {
        dispatch(fetchSuccess());
        let questions: QuestionObj[];
        questions = data.questions.map((que) => ({
          id: que.id,
          content: que.content,
          answer_type: que.answer_type,
          answer_value: 0,
        }));
        dispatch({type: GET_TEST_QUESTIONS, payload: {testId, questions}});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseTests = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCourseTests(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        let tests: TestObj[];
        tests = data.tests.map((test) => ({
          id: test.id,
          name: test.name,
          summary: test.summary,
          type: test.is_preTest ? 0 : 1,
          questions: [],
        }));
        dispatch({type: GET_COURSE_TESTS, payload: {courseId, tests}});
        tests.forEach(async (test) => {
          try {
            const {data, status} = await getTestQuestions(test.id);
            if (status === 200) {
              dispatch(fetchSuccess());
              let questions: QuestionObj[];
              questions = data.questions.map((que) => ({
                id: que.id,
                content: que.content,
                answer_type: que.answer_type,
                answer_value: 0,
              }));
              dispatch({
                type: GET_TEST_QUESTIONS,
                payload: {testId: test.id, questions},
              });
            } else {
              dispatch(
                fetchError(messages['message.somethingWentWrong'] as string),
              );
            }
          } catch (err) {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onCreateTest = (courseId: number, test: CreateTestDTO) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status, data} = await createTest(courseId, test);
      if (status === 201) {
        dispatch(fetchSuccess());
        let test: TestObj = {
          id: data.test.id,
          name: data.test.name,
          summary: data.test.summary,
          type: data.test.is_preTest ? 1 : data.test.is_postTest ? 0 : 0,
          questions: [],
        };
        dispatch({type: CREATE_NEW_TEST, payload: test});
        dispatch(showMessage(messages['test.created'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetEventLessons = (eventId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getEventLessons(eventId);
      if (status === 200) {
        dispatch({
          type: GET_EVENT_LESSONS,
          payload: {eventId, lessons: data.lessons},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onCreateEvent = (
  createEventDto: CreateEventDTO,
  courseId: number,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await createEvent(courseId, createEventDto);
      if (status === 201) {
        const event: EventObj = {
          id: data.event.id,
          name: data.event.name,
          desc: data.event.desc,
          lessons: [],
          completed: false,
        };
        dispatch(fetchSuccess());
        dispatch({type: CREATE_NEW_EVENT, payload: event});
        dispatch(showMessage(messages['event.created'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onCreateInvite = (
  createInviteDto: CreateInvitationtDTO,
  courseId: number,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await createInvitation(courseId, createInviteDto);
      if (status === 201) {
        dispatch(fetchSuccess());
        dispatch(showMessage(messages['event.created'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseEvents = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCourseEvents(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        const events: EventObj[] = data.events.map((event) => ({
          id: event.id,
          name: event.name,
          desc: event.desc,
          lessons: event.lessons,
          completed: event.student_status,
        }));
        dispatch({
          type: GET_COURSE_EVENTS,
          payload: {courseId, events},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseInvites = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCourseInvites(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_COURSE_INVITES,
          payload: {courseId, invites: data.invites},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseAuthors = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCourseAuthors(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_COURSE_AUTHORS,
          payload: {courseId, authors: data.authors},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetAuthorsList = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getAuthorsList();
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_AUTHORS_LIST,
          payload: {courseId, authors: data.authors},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};

export const onDeleteEvent = (eventId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteEvent(eventId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({type: DELETE_EVENT, payload: eventId});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onDeleteInvitation = (inviteId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteInvitation(inviteId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({type: DELETE_INVITE, payload: inviteId});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onDeleteAuthor = (authorId: number, courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteAuthor(authorId, courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({type: DELETE_AUTHOR, payload: authorId});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onAddAuthor = (authorId: number, courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await addAuthor(authorId, courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onDeleteTest = (testId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteTest(testId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({type: DELETE_TEST, payload: testId});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};
export const onDeleteLesson = (lessonId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteLesson(lessonId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({type: DELETE_LESSON, payload: lessonId});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onDeleteQuestion = (questionId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteQuestion(questionId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({type: DELETE_QUESTION, payload: questionId});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUpdateCourseDetail = (
  courseId: number,
  newCourse: CreateCourseDTO,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await updateCourse(courseId, newCourse);
      if (status === 200) {
        dispatch(fetchSuccess());
        const course: UpdatedCourseObj = {
          id: courseId,
          title: data.course.c_title,
          summary: data.course.summary,
          label:
            data.course.category === 'ME'
              ? [MeLabel]
              : data.course.category === 'TH'
              ? [ThLabel]
              : [],
        };
        dispatch({type: UPDATE_COURSE_DETAIL, payload: course});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetTestResults = (testId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getTestResults(testId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_TEST_RESULTS,
          payload: {answers: data.results.map((res) => +res.answer), testId},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetPreTest = (testId: number, selectedCourse: CourseObj) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    const postTest = selectedCourse.tests.find(
      (test) => test.type === TestType.POST,
    );
    try {
      if (postTest) {
        const {data, status} = await getTestResults(postTest.id);
        if (status === 200) {
          dispatch(fetchSuccess());
          if (data.results.length === 0) {
          }
        }
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUpdateInviteDetail = (
  inviteId: number,
  newInvite: CreateInvitationtDTO,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await updateInvite(inviteId, newInvite);
      if (status === 200) {
        dispatch(fetchSuccess());
        const invite: InviteObj = {
          id: inviteId,
          subject: data.invite.subject,
          desc: data.invite.desc,
          location: data.invite.location,
          invite_date: data.invite.invite_date,
          start_time: data.invite.start_time,
          end_time: data.invite.end_time,
          timezone: data.invite.timezone,
        };
        dispatch({type: UPDATE_INVITE_DETAIL, payload: {inviteId, invite}});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUpdateEventDetail = (
  eventId: number,
  newEvent: CreateEventDTO,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await updateEvent(eventId, newEvent);
      if (status === 200) {
        dispatch(fetchSuccess());
        const event: EventObj = {
          id: eventId,
          name: data.event.name,
          desc: data.event.desc,
          lessons: [],
          completed: false,
        };
        dispatch({type: UPDATE_EVENT_DETAIL, payload: {eventId, event}});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUpdateTestDetail = (testId: number, newTest: CreateTestDTO) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await updateTest(testId, newTest);
      if (status === 200) {
        dispatch(fetchSuccess());
        const test: UpdatedTestObj = {
          id: testId,
          name: data.test.name,
          summary: data.test.summary,
        };
        dispatch({type: UPDATE_TEST_DETAIL, payload: {testId, test}});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onSendMessageViaDigitByCourse = (
  courseId: number,
  message: string,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await sendMessageViaDigitByCourse(courseId, message);
      if (status === 200) {
        dispatch(fetchSuccess());

        dispatch(
          showMessage(
            '’Message has been sent to all the students in this course successfully',
          ),
        );
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUpdateLessonDetail = (
  lessonId: number,
  newLesson: CreateLessonDTO,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await updateLesson(lessonId, newLesson);
      if (status === 200) {
        dispatch(fetchSuccess());
        const lesson: UpdatedLessonObj = {
          id: lessonId,
          l_title: data.lesson.l_title,
          preview_name: data.lesson.preview_name,
          content: data.lesson.content,
        };
        dispatch({
          type: UPDATE_LESSON_DETAIL,
          payload: {lessonId, lesson},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUpdateQuestionDetail = (
  questionId: number,
  newQue: CreateQuestionDTO,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await updateQuestion(questionId, newQue);
      if (status === 200) {
        dispatch(fetchSuccess());
        const question: QuestionObj = {
          id: questionId,
          content: data.question.content,
          answer_type:
            data.question.answerType === '1'
              ? QuestionType.NUMERAL
              : QuestionType.TEXT,
          answer_value: 0,
        };
        dispatch({
          type: UPDATE_QUESTION_DETAIL,
          payload: {questionId, question},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onMarkEventAsCompleted = (eventId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await markEventAsCompleted(eventId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: MARK_EVENT_AS_COMPLETED,
          payload: eventId,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUploadFileToLesson = (lessonId: number, formData: FormData) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await uploadFileToLesson(lessonId, formData);
      if (status === 201) {
        dispatch(fetchSuccess());
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUploadVideoToLesson = (lessonId: number, formData: FormData) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await uploadVideoToLesson(lessonId, formData);
      if (status === 201) {
        dispatch(fetchSuccess());
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};
