import Settings from './Setting';
import CommonReducer from './CommonReducer';
import Auth from './Auth';
import ChatApp from './ChatApp';
import Courses from './Courses';
import Statistics from './Statistics';

const reducers = {
  settings: Settings,
  auth: Auth,
  common: CommonReducer,
  chatApp: ChatApp,
  courses: Courses,
  statistics: Statistics,
};

export default reducers;
