import {GetUserMessagesDTO, MessageDataObj} from 'types/models/apps/Chat';
import {dateFormat} from '../dateFormater';

export function getFileName(msg: string,prefixLength:number) {
  return msg.substring(prefixLength);
}

export function fileHandle(msg: GetUserMessagesDTO,prefixLength:number): MessageDataObj {

  return {
    id: msg.id,
    sender: {id: msg.contact.user.id, name: msg.contact.user.username},
    message:msg.content,
    message_type: 4,
    time: dateFormat(msg.timestamp),
  };
}
