import React from 'react';
import clsx from 'clsx';
import {Box, Avatar} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  ConnectionObj,
  MessageDataObj,
  MessageType,
} from 'types/models/apps/Chat';
import useStyles from './MessageItem.style';
import {GetMessage} from './MessageComponent';

interface ReceiverMessageItemProps {
  selectedUser: ConnectionObj;
  item: MessageDataObj;
}

const ReceiverMessageItem: React.FC<ReceiverMessageItemProps> = ({
  selectedUser,
  item,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.messageItemRoot, 'left')}
      display='flex'
      justifyContent={'flex-start'}>
      <Box className={classes.messageChatRoot}>
        <Box
          className={clsx(classes.messageTime, 'message-time')}
          component='span'>
          {item.sender.name === 'digit' ? 'DE' : item.sender.name} , {item.time}
        </Box>

        <Box display='flex' flexDirection='row'>
          <Box className={classes.messageChat}>
            {selectedUser.image ? (
              <Avatar
                className={classes.profilePic}
                src={'/assets/images/avatar/bot-icon.gif'}
              />
            ) : (
              <Avatar className={classes.profilePic}>
                {item.sender.name?.charAt(0).toUpperCase()}
              </Avatar>
            )}

            <Box className='message-info'>
              {GetMessage(item, classes)}
              {item.edited && (
                <Box className={classes.editRoot}>
                  <EditIcon />
                </Box>
              )}
            </Box>
          </Box>
          {item.message_type === MessageType.DOC ? (
            <Box className='pointer' component='span' mt='auto'>
              <a href={item.media!.url} download={item.media!.file_name}>
                <img alt='' src={'/assets/images/icon-download.svg'} />
              </a>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default ReceiverMessageItem;
