import {
  CourseActions,
  CREATE_NEW_COURSE,
  CREATE_NEW_EVENT,
  CREATE_NEW_LESSON,
  CREATE_NEW_QUESTION,
  CREATE_NEW_TEST,
  DELETE_COURSE,
  DELETE_EVENT,
  DELETE_INVITE,
  DELETE_AUTHOR,
  DELETE_LESSON,
  DELETE_QUESTION,
  DELETE_TEST,
  GET_COURSE_COMPLETION_RATE,
  GET_COURSE_COMPLETION_RATE_INCREASE,
  GET_COURSE_DETAIL,
  GET_COURSE_EVENTS,
  GET_COURSE_INVITES,
  GET_COURSE_AUTHORS,
  GET_AUTHORS_LIST,
  GET_COURSE_LESSONS_LIST,
  GET_COURSE_LIST,
  GET_COURSE_TESTS,
  GET_EVENT_LESSONS,
  GET_TEST_QUESTIONS,
  GET_TEST_RESULTS,
  UPDATE_COURSE_DETAIL,
  UPDATE_EVENT_DETAIL,
  UPDATE_INVITE_DETAIL,
  UPDATE_LESSON_DETAIL,
  UPDATE_QUESTION_DETAIL,
  UPDATE_TEST_DETAIL,
  MARK_EVENT_AS_COMPLETED,
} from 'types/actions/Course.action';
import {CourseObj} from 'types/models/apps/Course';

const initialState: {
  coursesList: CourseObj[];
  totalCourses: number;
  selectedCourse: CourseObj | null;
} = {
  coursesList: [],
  totalCourses: 0,
  selectedCourse: null,
};

const Courses = (state = initialState, action: CourseActions) => {
  switch (action.type) {
    case GET_COURSE_LIST:
      return {
        ...state,
        coursesList: action.payload.list,
        totalCourses: action.payload.total,
      };

    case CREATE_NEW_COURSE:
      return {
        ...state,
        coursesList: [...state.coursesList, action.payload],
        totalCourses: state.totalCourses + 1,
      };

    case CREATE_NEW_EVENT:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: state.selectedCourse?.events.concat(action.payload),
        },
      };

    case CREATE_NEW_LESSON:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: state.selectedCourse?.events.map((event) =>
            event.id === action.payload.eventId
              ? (event = {
                  ...event,
                  lessons: event.lessons.concat(action.payload.lesson),
                })
              : event,
          ),
        },
      };

    case GET_COURSE_LESSONS_LIST:
      return {
        ...state,
        coursesList: state.coursesList.map((course) => {
          var temp = Object.assign({}, course);
          // if (temp.id === action.payload.courseId)
          //   temp.lessons = action.payload.lessons;
          return temp;
        }),
      };
    case GET_EVENT_LESSONS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: state.selectedCourse?.events.map((event) =>
            event.id === action.payload.eventId
              ? {...event, lessons: action.payload.lessons}
              : event,
          ),
        },
      };
    case GET_COURSE_DETAIL:
      return {...state, selectedCourse: action.payload};

    case GET_COURSE_COMPLETION_RATE:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          completionRate: action.payload,
        },
      };

    case GET_COURSE_COMPLETION_RATE_INCREASE:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          completionRateIncrease: action.payload,
        },
      };

    case DELETE_COURSE:
      return {
        ...state,
        coursesList: state.coursesList.filter(
          (course) => course.id !== action.payload,
        ),
      };

    case GET_TEST_QUESTIONS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: state.selectedCourse?.tests?.map((test) =>
            test.id === action.payload.testId
              ? (test = {...test, questions: action.payload.questions})
              : test,
          ),
        },
      };

    case GET_COURSE_TESTS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: action.payload.tests,
        },
      };

    case CREATE_NEW_TEST:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: state.selectedCourse?.tests.concat(action.payload),
        },
      };

    case CREATE_NEW_QUESTION:
      return {
        ...state,
        selectedCourse: {
          tests: state.selectedCourse?.tests?.map((test) =>
            test.id === action.payload.testId
              ? (test = {
                  ...test,
                  questions: test.questions?.concat(action.payload.question),
                })
              : test,
          ),
        },
      };
    case GET_COURSE_EVENTS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: action.payload.events,
        },
      };
    case GET_COURSE_INVITES:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          invites: action.payload.invites,
        },
      };
    case GET_COURSE_AUTHORS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          authors: action.payload.authors,
        },
      };
    case GET_AUTHORS_LIST:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          authorList: action.payload.authors,
        },
      };

    case UPDATE_COURSE_DETAIL:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          ...action.payload,
        },
      };

    case GET_TEST_RESULTS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: state.selectedCourse?.tests.map((test) =>
            test.id === action.payload.testId
              ? (test = {
                  ...test,
                  questions: test.questions.map((que, index) => ({
                    ...que,
                    answer_value: action.payload.answers[index],
                  })),
                })
              : test,
          ),
        },
      };

    case UPDATE_EVENT_DETAIL:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: state.selectedCourse?.events.map((event) =>
            event.id === action.payload.eventId
              ? (event = {
                  ...action.payload.event,
                  lessons: event.lessons,
                  completed: false,
                })
              : event,
          ),
        },
      };
    case UPDATE_INVITE_DETAIL:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          invites: state.selectedCourse?.invites.map((invite) =>
            invite.id === action.payload.inviteId
              ? (invite = {...action.payload.invite})
              : invite,
          ),
        },
      };
    case UPDATE_TEST_DETAIL:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: state.selectedCourse?.tests.map((test) =>
            test.id === action.payload.testId
              ? (test = {...action.payload.test, questions: test.questions})
              : test,
          ),
        },
      };
    case UPDATE_LESSON_DETAIL:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: state.selectedCourse?.events.map(
            (event) =>
              (event = {
                ...event,
                lessons: event.lessons.map((lesson) =>
                  lesson.id === action.payload.lessonId
                    ? (lesson = {...action.payload.lesson, files: []})
                    : lesson,
                ),
              }),
          ),
        },
      };

    case DELETE_EVENT:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: state.selectedCourse?.events.filter(
            (event) => event.id !== action.payload,
          ),
        },
      };
    case DELETE_INVITE:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          invites: state.selectedCourse?.invites.filter(
            (invite) => invite.id !== action.payload,
          ),
        },
      };
    case DELETE_AUTHOR:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          authors: state.selectedCourse?.authors.filter(
            (author) => author.id !== action.payload,
          ),
        },
      };

    case DELETE_TEST:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: state.selectedCourse?.tests.filter(
            (test) => test.id !== action.payload,
          ),
        },
      };
    case DELETE_LESSON:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: state.selectedCourse?.events.map(
            (event) =>
              (event = {
                ...event,
                lessons: event.lessons.filter(
                  (lesson) => lesson.id !== action.payload,
                ),
              }),
          ),
        },
      };

    case DELETE_QUESTION:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: state.selectedCourse?.tests.map(
            (test) =>
              (test = {
                ...test,
                questions: test.questions.filter(
                  (que) => que.id !== action.payload,
                ),
              }),
          ),
        },
      };

    case UPDATE_QUESTION_DETAIL:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: state.selectedCourse?.tests.map(
            (test) =>
              (test = {
                ...test,
                questions: test.questions.map((que) =>
                  que.id === action.payload.questionId
                    ? (que = action.payload.question)
                    : que,
                ),
              }),
          ),
        },
      };

    case MARK_EVENT_AS_COMPLETED:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: state.selectedCourse?.events.map((event) =>
            event.id === action.payload
              ? (event = {
                  ...event,
                  completed: true,
                })
              : event,
          ),
        },
      };

    default:
      return state;
  }
};
export default Courses;
