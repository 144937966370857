export function dateFormat(time: string): string {
  const dtfUS = new Intl.DateTimeFormat('en', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
  return dtfUS.format(new Date(time));
}
