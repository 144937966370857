import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import {appsConfig} from './apps';
import {extraPagesConfigs} from './extraPages';

const routeConfigs = [...authRouteConfig, ...appsConfig, ...extraPagesConfigs];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
