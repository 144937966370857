import {UserProfile} from 'types/models/apps/Auth';
import {AppActions} from '../../types';
import {
  GET_USER_PROFILE,
  SET_AUTH_TOKEN,
  SET_LOGIN_SUCCESS,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';
import {AuthUser} from '../../types/models/AuthUser';

const INIT_STATE: {
  user: AuthUser | null;
  token: string | null;
  firstLogin: boolean;
  profile: UserProfile | null;
} = {
  user: null,
  token: null,
  firstLogin: false,
  profile: null,
};

const Auth = (state = INIT_STATE, action: AppActions) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case SET_LOGIN_SUCCESS: {
      return {
        ...state,
        firstLogin: !state.firstLogin,
      };
    }
    case GET_USER_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    default:
      return state;
  }
};
export default Auth;
