import {Response} from '../Common';

export interface LabelObj {
  id: number;
  name: string;
  color: string;
}

export enum QuestionType {
  NUMERAL = 1,
  TEXT = 2,
}

export interface QuestionObj {
  id: number;
  content: string;
  answer_type: QuestionType;
  answer_value: number;
}

export enum TestType {
  PRE = 0,
  POST = 1,
}

export interface TestObj {
  id: number;
  name: string;
  summary: string;
  questions: QuestionObj[];
  type?: TestType;
}

export interface CourseObj {
  id: number;
  title: string;
  label: LabelObj[];
  createdOn?: string;
  isDeleted?: boolean;
  summary: string;
  events: EventObj[];
  invites: InviteObj[];
  authors: AuthorObj[];
  authorList: AuthorObj[];
  completionRate?: number;
  completionRateIncrease?: CompletionRateIncrease;
  tests: TestObj[];
  disabled?: boolean;
  chat?: {id: number; title: string};
  startDate?: string;
  endDate?: string;
}

export interface GetCoursesDTO {
  id: number;
  c_title: string;
  summary: string;
  category: string;
  author: number[];
  preview_name: string;
  chat: {id: number; title: string};
  disabled: boolean;
  start_time: string;
  end_time: string;
}

export interface GetCoursesResponse
  extends Response<{
    courses: GetCoursesDTO[];
  }> {}

export interface CreateCourseReponse
  extends Response<{course: GetCoursesDTO}> {}

export interface CreateCourseDTO {
  c_title: string;
  summary: string;
  category: string;
  start_time: string;
  end_time: string;
  chat_group_name: string;
}

export interface CreateLessonObj {
  id: number;
  l_title: string;
  preview_name: string;
  content: string;
}

export interface CreateLessonReponse
  extends Response<{lesson: CreateLessonObj}> {}

export interface CreateLessonDTO {
  l_title: string;
  preview_name: string;
  content: string;
}

export interface LessonObj {
  id: number;
  l_title: string;
  content: string;
  files: FileObj[];
  video_link?: string;
  image_link?: string;
}

export interface FileObj {
  id: number;
  name: string;
  type: string;
  url: string;
  size: number;
}

export interface GetCourseLessonsReponse
  extends Response<{lessons: LessonObj[]}> {}

export interface GetCourseDetailDTO {
  id: number;
  c_title: string;
  summary: string;
  category: string;
  author: number[];
  preview_name: string;
  events: EventObj[];
  invites: InviteObj[];
  tests: GetTestDTO[];
  chat: {id: number; title: string};
  disabled: boolean;
  start_time: string;
  end_time: string;
}

export interface EventObj {
  id: number;
  name: string;
  desc: string;
  lessons: LessonObj[];
  completed: boolean;
}

export interface InviteObj {
  id: number;
  subject: string;
  desc: string;
  location: string;
  timezone: string;
  invite_date: string;
  start_time: string;
  end_time: string;
}
export interface AuthorObj {
  id: number;
  username: string;
}

export interface GetCourseReponse
  extends Response<{course: GetCourseDetailDTO}> {}

export interface GetLessonDTO {
  id: number;
  l_title: string;
  content: string;
  files: GetFileDTO[];
}

export interface GetFileDTO {
  id: number;
  name: string;
  size: number;
  type: string;
  url: string;
}

export interface EnrolCourseReponse extends Response<{status: string}> {}

export interface GetCourseStatusReponse extends Response<{status: number}> {}

export interface SendAnswersResponse extends Response<{status: boolean}> {}

export interface SendAnswersDTO {
  q_ids: number[];
  values: string[];
}

export interface GetCompletionRateResponse
  extends Response<{comp_rate: number}> {}

export interface CompletionRateIncrease {
  students_num: number;
  cir_statistics: any[];
}

export interface GetCompletionIncreaseRateResponse
  extends Response<{cir: CompletionRateIncrease}> {}

export interface DeleteCourseResponse extends Response<{res: boolean}> {}

export interface DeleteEventResponse extends Response<{res: boolean}> {}
export interface DeleteAuthorResponse extends Response<{res: boolean}> {}
export interface AddAuthorResponse extends Response<{res: boolean}> {}
export interface DeleteTestResponse extends Response<{res: boolean}> {}

export interface DeleteQuestionResponse extends Response<{res: boolean}> {}

export interface DeleteLessonResponse extends Response<{res: boolean}> {}

export interface UpdateCourseResponse
  extends Response<{course: CreateCourseDTO}> {}

export interface UpdateTestResponse extends Response<{test: CreateTestDTO}> {}

export interface UpdateEventResponse
  extends Response<{event: UpdateEventDTO}> {}

export interface UpdateInviteResponse
  extends Response<{invite: UpdateInvitationtDTO}> {}

export interface UpdateLessonResponse
  extends Response<{lesson: UpdatedLessonObj}> {}

export interface UpdatedQuestionObj {
  id: number;
  content: string;
  answerType: string;
}

export interface UpdateQuestionResponse
  extends Response<{question: UpdatedQuestionObj}> {}
export interface GetQuestionDTO {
  id: number;
  content: string;
  answer_type: number;
  test: number;
}

export interface GetTestQuestionsResponse
  extends Response<{questions: GetQuestionDTO[]}> {}

export interface GetTestDTO {
  id: number;
  name: string;
  summary: string;
  course: number;
  is_preTest: boolean;
  is_postTest: boolean;
  questions?: QuestionObj[];
}

export interface GetCourseTestsResponse
  extends Response<{tests: GetTestDTO[]}> {}

export interface CreateTestResponse extends Response<{test: GetTestDTO}> {}

export interface CreateTestDTO {
  name: string;
  summary: string;
  is_preTest?: number;
  is_postTest?: number;
}

export interface CreateQuestionDTO {
  content: string;
  answer_type: number;
}

export interface CreateQuestionResponse
  extends Response<{questions: GetQuestionDTO}> {}

export interface UnenrolCourseReponse extends Response<{status: string}> {}

export interface GetEventResponse extends Response<{event: EventObj}> {}

export interface GetCourseEventsDTO {
  id: number;
  name: string;
  desc: string;
  lessons: LessonObj[];
  student_status: boolean;
}

export interface GetCourseEventsResponse
  extends Response<{events: GetCourseEventsDTO[]}> {}

export interface GetCourseInvitesResponse
  extends Response<{invites: InviteObj[]}> {}

export interface GetCourseAuthorsResponse
  extends Response<{authors: AuthorObj[]}> {}

export interface GetEventLessonsResponse
  extends Response<{lessons: LessonObj[]}> {}

export interface CreateEventDTO {
  name: string;
  desc: string;
}
export interface CreateInvitationtDTO {
  subject: string;
  desc: string;
  location: string;
  timezone: string;
  invite_date: string;
  start_time: string;
  end_time: string;
}
export interface CreateInviteResponse extends Response<{invite: InviteObj}> {}
export interface CreateEventObj {
  id: number;
  name: string;
  desc: string;
}

export interface CreateEventResponse
  extends Response<{event: CreateEventObj}> {}

export interface UpdatedCourseObj {
  id: number;
  title: string;
  summary: string;
  label: LabelObj[];
}

export interface UpdateEventDTO {
  id: number;
  name: string;
  desc: string;
}
export interface UpdateInvitationtDTO {
  id: number;
  subject: string;
  desc: string;
  location: string;
  timezone: string;
  invite_date: string;
  start_time: string;
  end_time: string;
}

export interface UpdatedEventObj {
  id: number;
  name: string;
  desc: string;
}

export interface UpdatedInviteObj {
  id: number;
  subject: string;
  desc: string;
  location: string;
  timezone: string;
  invite_date: string;
  start_time: string;
  end_time: string;
}

export interface UpdateLessonDTO {
  id: number;
  l_title: string;
  preview_name: string;
  content: string;
}

export interface UpdatedLessonObj {
  id: number;
  l_title: string;
  preview_name: string;
  content: string;
}

export interface UpdatedLessonObj {
  id: number;
  l_title: string;
  preview_name: string;
  content: string;
}

export interface UpdatedTestObj {
  id: number;
  name: string;
  summary: string;
}

export interface GetTestResultsDTO {
  questions: number;
  answer: string;
}

export interface GetTestResultsResponse
  extends Response<{results: GetTestResultsDTO[]}> {}

export interface MarkEventAsCompletedResponse
  extends Response<{result: string}> {}

export interface UploadFileToLessonResponse
  extends Response<{result: string}> {}

export interface UploadVideoToLessonResponse
  extends Response<{result: string}> {}
