import {GetUserMessagesDTO, MessageDataObj} from 'types/models/apps/Chat';
import {fileHandle} from '../clickableHandler';
import {dateFormat} from '../dateFormater';
import {equationHandle} from '../equationHandler';
import {questionHandle} from '../questionHandler';
import {videoHandle} from '../videoHandler';

export function ignoredMessage(msg: string): boolean {
  return ['{MSC_X_T*}', '{MSC_Script*}', '{MSC_Exec*}'].some((word) =>
    msg.startsWith(word),
  );
}

export function messageHandle(msg: GetUserMessagesDTO): MessageDataObj {
  return msg.content.toLocaleLowerCase().startsWith('{equation*}')
    ? equationHandle(msg)
    : msg.content.toLocaleLowerCase().startsWith('{media*}')
    ? videoHandle(msg)
    : msg.content.toLocaleLowerCase().startsWith('{question*}')
    ? questionHandle(msg)
    : msg.content.toLocaleLowerCase().startsWith('{x_t*}') 
    ? fileHandle(msg,6)
    : msg.content.toLocaleLowerCase().startsWith('{x_t_load*}') 
    ? fileHandle(msg,11)
    : {
        id: msg.id,
        sender: {
          id: msg.contact.user.id,
          name: msg.contact.user.username,
        },
        message: msg.content,
        message_type: 3,
        time: dateFormat(msg.timestamp),
      };
}
