import {
  ConnectionObj,
  CourseObj,
  FileObj,
  GetUnansweredQuestionsDTO,
  MessageObj,
  StatisticsNumbers,
  SurveyObj,
  UserObj,
} from 'types/models/apps/Statistics';

export const GET_UNANSWERED_QUESTIONS = 'GET_UNANSWERED_QUESTIONS';
export const GET_STATISTICS = 'GET_STATISTICS';
export const GET_CONNECTIONS_LIST = 'GET_CONNECTIONS_LIST';
export const GET_USER_MESSAGES = 'GET_USER_MESSAGES';
export const SELECT_USER = 'SELECT_USER';
export const GET_USERS = 'GET_USERS';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_USER_BLOCK_STATUS = 'CHANGE_USER_BLOCK_STATUS';
export const VERIFY_USER = 'VERIFY_USER';
export const GET_COURSE_LIST = 'GET_COURSE_LIST';
export const GET_USER_SURVERYS = 'GET_USER_SURVERYS';
export const DELETE_MESSAGES_WITH_DIGITAL_ENGINEER =
  'DELETE_MESSAGES_WITH_DIGITAL_ENGINEER';
export const GET_AUTOMATION_FILES = 'GET_AUTOMATION_FILES';
export const DELETE_AUTOMATION_FILES = 'DELETE_AUTOMATION_FILES';
export const UPLOAD_AUTOMATION_FILES = 'UPLOAD_AUTOMATION_FILES';

export interface getUnansweredQuestionsActions {
  type: typeof GET_UNANSWERED_QUESTIONS;
  payload: GetUnansweredQuestionsDTO[];
}

export interface GetStatisticsActions {
  type: typeof GET_STATISTICS;
  payload: StatisticsNumbers;
}

export interface GetConnectionListActions {
  type: typeof GET_CONNECTIONS_LIST;
  payload: ConnectionObj[];
}

export interface GetUserMessageActions {
  type: typeof GET_USER_MESSAGES;
  payload: MessageObj;
}

export interface SelectUserActions {
  type: typeof SELECT_USER;
  payload: ConnectionObj;
}

export interface GetUsersActions {
  type: typeof GET_USERS;
  payload: UserObj[];
}

export interface DeleteUserActions {
  type: typeof DELETE_USER;
  payload: number;
}

export interface ChangeUserBockStatusActions {
  type: typeof CHANGE_USER_BLOCK_STATUS;
  payload: number;
}

export interface VerifyUserActions {
  type: typeof VERIFY_USER;
  payload: number;
}

export interface GetCourseListActions {
  type: typeof GET_COURSE_LIST;
  payload: CourseObj[];
}

export interface GetUserSurveysActions {
  type: typeof GET_USER_SURVERYS;
  payload: SurveyObj[];
}

export interface DeleteMessagesWithDigitalEngineerActions {
  type: typeof DELETE_MESSAGES_WITH_DIGITAL_ENGINEER;
  payload: number;
}

export interface GetAutomationFilesActions {
  type: typeof GET_AUTOMATION_FILES;
  payload: FileObj[];
}

export interface DeleteAutomationFileActions {
  type: typeof DELETE_AUTOMATION_FILES;
  payload: number;
}

export interface UploadAutomationFileActions {
  type: typeof UPLOAD_AUTOMATION_FILES;
  payload: FileObj;
}

export type StatisticsActions =
  | getUnansweredQuestionsActions
  | GetConnectionListActions
  | GetUserMessageActions
  | SelectUserActions
  | GetUsersActions
  | GetStatisticsActions
  | ChangeUserBockStatusActions
  | VerifyUserActions
  | GetCourseListActions
  | GetUserSurveysActions
  | DeleteMessagesWithDigitalEngineerActions
  | GetAutomationFilesActions
  | DeleteAutomationFileActions
  | UploadAutomationFileActions
  | DeleteUserActions;
